import {defineStore} from "pinia";
import config from '@/config'
import {
  type menu,
  sidebarAnalytics,
  sidebarDefault,
  sidebarLeads
} from "~/components/lc/Full/vertical-sidebar/sidebarDefault";
import {api} from "~/types/api/strapi";


export const useCustomizerStore = defineStore({
  id: "customizer",
  state: () => ({
    sidebarItems: sidebarDefault,
    Sidebar_drawer: config.Sidebar_drawer,
    Customizer_drawer: config.Customizer_drawer,
    mini_sidebar: config.mini_sidebar,
    mini_hover: false,
    setHorizontalLayout: config.setHorizontalLayout, // Horizontal layout
    setRTLLayout: config.setRTLLayout, // RTL layout
    actTheme: config.actTheme,
    boxed: config.boxed,
    setBorderCard: config.setBorderCard,
    developmentSidebarItems: config.developmentSidebarItems,
    featureFlags: {} as Record<string, string>,
  }),

  getters: {
    getDevelopmentSidebarItems(): any {
      return this.developmentSidebarItems
    },
    getSidebarItems(): any {
      return this.sidebarItems
    },
    getMiniHover(): any {
      return this.mini_hover
    },
    getMiniSidebar(): any {
      return this.mini_sidebar
    },
    getActiveTheme(): any {
      return this.actTheme
    }
  },
  actions: {
    async fetchFeatureFlags(userId: string) {
      try {
        const runtimeConfig = useRuntimeConfig()
        const result: any = await $fetch(`/custom-user-settings?filters[userID]=${userId}&populate=*`, {
          baseURL: runtimeConfig.public.strapiApiUrl + api,
        })
        const attributes = result.data.length ? result.data[0].attributes : {}
        this.featureFlags = { ...attributes.FeatureFlag } || {};
      } catch (error) {
        this.featureFlags = {};
        console.log('error :>> ', error);
      }
    },
    setSidebarItemsViaProducts(products: any = []) {
      let array: menu[] = sidebarDefault
      // TODO: remove from here when there is a product for analytics
      array = [...array, ...(sidebarAnalytics)]
      products.map((product: any) => {
        if (product?.product?.key === 'analytics') {
          array = [...array, ...(sidebarAnalytics)]
        } else if (product?.product?.key === 'leads') {
          array = [...array, ...(sidebarLeads)]
        }
      })
      //TODO: if all products are disabled, remove the header as well
      //TODO: find a recursive way to check featureFlag for childrens as well
      this.sidebarItems = array.filter(s => s.featureFlag ? this.featureFlags[s.featureFlag] : true);
    },
    SET_SIDEBAR_DRAWER() {
      this.Sidebar_drawer = !this.Sidebar_drawer;
    },
    SET_MINI_HOVER(payload: any) {
      this.mini_hover = payload;
    },
    SET_MINI_SIDEBAR(payload: any) {
      this.mini_sidebar = payload;
    },
    SET_CUSTOMIZER_DRAWER(payload: any) {
      this.Customizer_drawer = payload;
    },
    SET_DEVELOPMENT_SIDEBAR_ITEMS(payload: any) {
      this.developmentSidebarItems = payload;
    },
    SET_LAYOUT(payload: any) {
      this.setHorizontalLayout = payload;
    },
    SET_THEME(payload: any) {
      this.actTheme = payload;
    },
    SET_CARD_BORDER(payload: any) {
      this.setBorderCard = payload
    }
  },
  persist: true,
});
