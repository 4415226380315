import {LayoutDashboardIcon,ArrowAutofitWidthIcon, PointIcon, BuildingSkyscraperIcon, SettingsIcon, HomeIcon, BuildingStoreIcon} from 'vue-tabler-icons';
import {shallowRef} from 'vue'
export interface menu {
    header?: string;
    headerApp?: string;
    title?: string;
    purchased?: boolean;
    key?: string;
    icon?: any;
    to?: string;
    headerColor?: string;
    chip?: string;
    chipBgColor?: string;
    chipColor?: string;
    chipVariant?: string;
    chipIcon?: string;
    children?: menu[];
    disabled?: boolean;
    type?: string;
    featureFlag?: string;
    subCaption?: string;
}

const env = process.env.NODE_ENV;
const sidebarDevelopment: menu[] = [
    {header: "Home"},
    {
        title: "Modern",
        icon: 'ApertureIcon',
        chip: "New",
        chipColor: "surface",
        chipBgColor: "secondary",
        to: "/demo/dashboards/modern",
    },
    {
        title: "eCommerce",
        icon: 'ShoppingCartIcon',
        to: "/demo/dashboards/ecommerce",
    },
    {header: "Apps"},
    {
        title: "Contact",
        icon: 'BoxIcon',
        to: "/demo/apps/contacts",
        chip: "2",
        chipColor: "surface",
        chipBgColor: "secondary",
    },

    {
        title: "Blog",
        icon: 'ChartDonut3Icon',
        to: "/demo/",
        children: [
            {
                title: "Posts",
                icon: 'PointIcon',
                to: "/demo/apps/blog/posts",
            },
            {
                title: "Detail",
                icon: 'PointIcon',
                to: "/demo/apps/blog/early-black-friday-amazon-deals-cheap-tvs-headphones",
            },
        ],
    },
    {
        title: "E-Commerce",
        icon: 'BasketIcon',
        to: "/demo/ecommerce/",
        children: [
            {
                title: "Shop",
                icon: 'PointIcon',
                to: "/demo/apps/ecommerce/products",
            },
            {
                title: "Detail",
                icon: 'PointIcon',
                to: "/demo/apps/ecommerce/product/detail/1",
            },
            {
                title: "Liste",
                icon: 'PointIcon',
                to: "/demo/apps/ecommerce/productlist",
            },
            {
                title: "Checkout",
                icon: 'PointIcon',
                to: "/demo/apps/ecommerce/checkout",
            },
        ],
    },
    {
        title: "Chats",
        icon: 'Message2Icon',
        to: "/demo/apps/chats",
    },
    {
        title: "User Profile",
        icon: 'UserCircleIcon',
        to: "/demo/",
        children: [
            {
                title: "Profile",
                icon: 'PointIcon',
                to: "/demo/apps/user/profile",
            },
            {
                title: "Followers",
                icon: 'PointIcon',
                to: "/demo/apps/user/profile/followers",
            },
            {
                title: "Friends",
                icon: 'PointIcon',
                to: "/demo/apps/user/profile/friends",
            },
            {
                title: "Gallery",
                icon: 'PointIcon',
                to: "/demo/apps/user/profile/gallery",
            },
        ],
    },
    {
        title: "Notes",
        icon: 'FilesIcon',
        to: "/demo/apps/notes",
    },

    {header: "Pages"},
    {
        title: "Pricing",
        icon: 'CurrencyDollarIcon',
        to: "/demo/theme-pages/pricing",
    },
    {
        title: "Account Setting",
        icon: 'UserCircleIcon',
        to: "/demo/theme-pages/account-settings",
    },
    {
        title: "FAQ",
        icon: 'HelpIcon',
        to: "/demo/theme-pages/faq",
    },
    {
        title: "Widget",
        icon: 'LayoutIcon',
        to: "/demo/widget-card",
        children: [
            {
                title: "Cards",
                icon: 'PointIcon',
                to: "/demo/widgets/cards",
            },
            {
                title: "Banners",
                icon: 'PointIcon',
                to: "/demo/widgets/banners",
            },
            {
                title: "Charts",
                icon: 'PointIcon',
                to: "/demo/widgets/charts",
            },
        ],
    },
    {
        title: "Landing Page",
        icon: 'AppWindowIcon',
        to: "/demo/theme-pages/landingpage",
    },

    {header: "Forms"},
    {
        title: "Form Elements",
        icon: 'AppsIcon',
        to: "/demo/components/",
        children: [
            {
                title: "Autocomplete",
                icon: 'PointIcon',
                to: "/demo/forms/form-elements/autocomplete",
            },
            {
                title: "Combobox",
                icon: 'PointIcon',
                to: "/demo/forms/form-elements/combobox",
            },
            {
                title: "Button",
                icon: 'PointIcon',
                to: "/demo/forms/form-elements/button",
            },
            {
                title: "Checkbox",
                icon: 'PointIcon',
                to: "/demo/forms/form-elements/checkbox",
            },
            {
                title: "Custom Inputs",
                icon: 'PointIcon',
                to: "/demo/forms/form-elements/custominputs",
            },
            {
                title: "File Inputs",
                icon: 'PointIcon',
                to: "/demo/forms/form-elements/fileinputs",
            },
            {
                title: "Radio",
                icon: 'PointIcon',
                to: "/demo/forms/form-elements/radio",
            },
            {
                title: "Date Time",
                icon: 'PointIcon',
                to: "/demo/forms/form-elements/date-time",
            },
            {
                title: "Select",
                icon: 'PointIcon',
                to: "/demo/forms/form-elements/select",
            },
            {
                title: "Slider",
                icon: 'PointIcon',
                to: "/demo/forms/form-elements/slider",
            },
            {
                title: "Switch",
                icon: 'PointIcon',
                to: "/demo/forms/form-elements/switch",
            },
        ],
    },
    {
        title: "Form Layout",
        icon: 'FileTextIcon',
        to: "/demo/forms/form-layouts",
    },
    {
        title: "Form Horizontal",
        icon: 'BoxAlignBottomIcon',
        to: "/demo/forms/form-horizontal",
    },
    {
        title: "Form Vertical",
        icon: 'BoxAlignLeftIcon',
        to: "/demo/forms/form-vertical",
    },
    {
        title: "Form Custom",
        icon: 'FileDotsIcon',
        to: "/demo/forms/form-custom",
    },
    {
        title: "Form Validation",
        icon: 'FilesIcon',
        to: "/demo/forms/form-validation",
    },
    {
        title: "Editor",
        icon: 'EditIcon',
        to: "/demo/forms/editor",
    },

    {header: "Tables"},
    {
        title: "Basic Table",
        icon: 'BorderAllIcon',
        to: "/demo/tables/basic",
    },
    {
        title: "Dark Table",
        icon: 'BorderHorizontalIcon',
        to: "/demo/tables/dark",
    },
    {
        title: "Density Table",
        icon: 'BorderInnerIcon',
        to: "/demo/tables/density",
    },
    {
        title: "Fixed Header Table",
        icon: 'BorderTopIcon',
        to: "/demo/tables/fixed-header",
    },
    {
        title: "Height Table",
        icon: 'BorderVerticalIcon',
        to: "/demo/tables/height",
    },
    {
        title: "Editable Table",
        icon: 'BorderStyle2Icon',
        to: "/demo/tables/editable",
    },
    {header: "UI"},
    {
        title: "UI Components",
        icon: 'BoxIcon',
        to: "#",
        children: [
            {
                title: "Alert",
                icon: 'PointIcon',
                to: "/demo/ui-components/alert",
            },
            {
                title: "Accordion",
                icon: 'PointIcon',
                to: "/demo/ui-components/accordion",
            },
            {
                title: "Avatar",
                icon: 'PointIcon',
                to: "/demo/ui-components/avatar",
            },
            {
                title: "Chip",
                icon: 'PointIcon',
                to: "/demo/ui-components/chip",
            },
            {
                title: "Dialog",
                icon: 'PointIcon',
                to: "/demo/ui-components/dialogs",
            },
            {
                title: "Liste",
                icon: 'PointIcon',
                to: "/demo/ui-components/list",
            },
            {
                title: "Menus",
                icon: 'PointIcon',
                to: "/demo/ui-components/menus",
            },
            {
                title: "Rating",
                icon: 'PointIcon',
                to: "/demo/ui-components/rating",
            },
            {
                title: "Tabs",
                icon: 'PointIcon',
                to: "/demo/ui-components/tabs",
            },
            {
                title: "Tooltip",
                icon: 'PointIcon',
                to: "/demo/ui-components/tooltip",
            },
            {
                title: "Typography",
                icon: 'PointIcon',
                to: "/demo/ui-components/typography",
            },
        ],
    },
    {header: "Charts"},
    {
        title: "Line",
        icon: 'ChartLineIcon',
        to: "/demo/charts/line-chart",
    },
    {
        title: "Gredient",
        icon: 'ChartArcsIcon',
        to: "/demo/charts/gredient-chart",
    },
    {
        title: "Area",
        icon: 'ChartAreaIcon',
        to: "/demo/charts/area-chart",
    },
    {
        title: "Candlestick",
        icon: 'ChartCandleIcon',
        to: "/demo/charts/candlestick-chart",
    },
    {
        title: "Column",
        icon: 'ChartDotsIcon',
        to: "/demo/charts/column-chart",
    },
    {
        title: "Doughnut & Pie",
        icon: 'ChartDonut3Icon',
        to: "/demo/charts/doughnut-pie-chart",
    },
    {
        title: "Radialbar & Radar",
        icon: 'ChartRadarIcon',
        to: "/demo/charts/radialbar-chart",
    },

    {header: "Auth"},

    {
        title: "Login",
        icon: 'LoginIcon',
        to: "#",
        children: [
            {
                title: "Side Login",
                icon: 'PointIcon',
                to: "/demo/auth/login",
            },
            {
                title: "Boxed Login",
                icon: 'PointIcon',
                to: "/demo/auth/login2",
            },
        ],
    },
    {
        title: "Register",
        icon: 'UserPlusIcon',
        to: "#",
        children: [
            {
                title: "Side Register",
                icon: 'PointIcon',
                to: "/demo/auth/register",
            },
            {
                title: "Boxed Register",
                icon: 'PointIcon',
                to: "/demo/auth/register2",
            },
        ],
    },
    {
        title: "Forgot Password",
        icon: 'RotateIcon',
        to: "#",
        children: [
            {
                title: "Side Forgot Password",
                icon: 'PointIcon',
                to: "/demo/auth/forgot-password",
            },
            {
                title: "Boxed Forgot Password",
                icon: 'PointIcon',
                to: "/demo/auth/forgot-password2",
            },
        ],
    },
    {
        title: "Two Steps",
        icon: 'ZoomCodeIcon',
        to: "#",
        children: [
            {
                title: "Side Two Steps",
                icon: 'SettingsIcon',
                to: "/demo/auth/two-step",
            },
            {
                title: "Boxed Two Steps",
                icon: 'SettingsIcon',
                to: "/demo/auth/two-step2",
            },
        ],
    },

    {
        title: "Error",
        icon: 'AlertCircleIcon',
        to: "/demo/auth/404",
    },
    {
        title: "Maintenance",
        icon: 'SettingsIcon',
        to: "/demo/auth/maintenance",
    },

    {header: "Others"},
    {
        title: "Menu Level",
        icon: 'BoxMultipleIcon',
        to: "#",
        children: [
            {
                title: "Level 1",
                icon: 'PointIcon',
                to: "/demo/",
            },
            {
                title: "Level 1",
                icon: 'PointIcon',
                to: "/demo/",
                children: [
                    {
                        title: "Level 2",
                        icon: 'PointIcon',
                        to: "/demo/",
                    },
                    {
                        title: "Level 2",
                        icon: 'PointIcon',
                        to: "/demo/",
                        children: [
                            {
                                title: "Level 3",
                                icon: 'PointIcon',
                                to: "/demo/",
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        title: "Disabled",
        icon: 'CircleOffIcon',
        disabled: true,
        to: "/demo/",
    },
    {
        title: "Sub Caption",
        icon: 'StarIcon',
        subCaption: "This is the subtitle",
        to: "/demo/",
    },
    {
        title: "Chip",
        icon: 'AwardIcon',
        chip: "9",
        chipColor: "surface",
        chipBgColor: "primary",
        to: "/demo/",
    },
    {
        title: "Outlined",
        icon: 'MoodSmileIcon',
        chip: "outline",
        chipColor: "primary",
        chipVariant: "outlined",
        to: "/demo/",
    },
    {
        title: "External Link",
        icon: 'StarIcon',
        to: "/demo/",
        type: "external",
    },
]
const sidebarAnalytics: menu[] = [
    {header: "Integra", headerApp: "Analytics", headerColor: "analyticsPrimary"},
    {
        title: "Dashboard",
        // TODO: add featureFlag for analytics_dashboard in cms
        // featureFlag: 'analytics_dashboard',
        icon: 'LayoutDashboardIcon',
        to: "/analytics/dashboard",
    },
    // {
    //     title: "Einstellungen",
    //     // TODO: add featureFlag for analytics_dashboard in cms
    //     // featureFlag: 'analytics_settings',
    //     icon: 'SettingsIcon',
    //     to: "/analytics/settings",
    // }
];
const sidebarLeads: menu[] = [
    {header: "Integra", headerApp: "Leads", headerColor: "primary"},
    {
        title: "Dashboard",
        icon: 'LayoutDashboardIcon',
        to: "/leads/dashboard",
    },
    {
        title: "Unternehmen",
        icon: 'BuildingSkyscraperIcon',
        to: "/leads/companies/list",
    },
    {
        title: "Einstellungen",
        featureFlag: 'leads_settings',
        icon: 'SettingsIcon',
        to: "/leads/settings",
    }
];
const sidebarInteprotect: menu[] = [
    {header: "Datenschutz"},
    {
        title: "Dashboard",
        icon: 'LayoutDashboardIcon',
        to: "/dataprotection/dashboard",
    },
    {
        title: "TOM",
        icon: 'ArrowAutofitWidthIcon',
        to: "/dataprotection/tom",
        children: [
            {
                title: "Liste",
                icon: 'PointIcon',
                to: "/dataprotection/tom/list",
            }
        ]
    },
    {
        title: "Whistleblower",
        icon: 'Flag3Icon',
        to: "/dataprotection/whistleblower",
        children: [
            {
                title: "Liste",
                icon: 'PointIcon',
                to: "/dataprotection/whistleblower/list",
            },
            {
                title: "Settings",
                icon: 'PointIcon',
                to: "/dataprotection/whistleblower/settings",
            }
        ]
    },
];

const sidebarDefault: menu[] = [
    {header: "Integra", headerApp: "Hub"},
    {
        title: "Hub",
        icon: 'HomeIcon',
        to: "/integrations/hub",
    },
    {
        title: "Integrationen",
        icon: 'BuildingStoreIcon',
        to: "/integrations/shop",
        featureFlag: 'shop'
    },
];

export {sidebarDefault, sidebarDevelopment, sidebarInteprotect, sidebarAnalytics, sidebarLeads};
