// MARK: - API
export const api: string = '/api'

// MARK: - PRODUCTS
export const integrahub: string = '/integrahub'
export const integraleads: string = '/integraleads'

// MARK: - TYPES
type StrapiDefaultAttributes = {
    attributes: {
        createdAt: string;
        updatedAt: string;
        publishedAt: string;
        locale: string;
    }
};
type StrapiDefaultImage = {
    id: number;
    attributes: {
        name: string;
        alternativeText: string;
        caption: string;
        width: number;
        height: number;
        formats: {
            small: {
                name: string;
                hash: string;
                ext: string;
                mime: string;
                width: number;
                height: number;
                size: number;
                path: string | null;
                url: string;
            },
            medium: {
                name: string;
                hash: string;
                ext: string;
                mime: string;
                width: number;
                height: number;
                size: number;
                path: string | null;
                url: string;
            },
            large: {
                name: string;
                hash: string;
                ext: string;
                mime: string;
                width: number;
                height: number;
                size: number;
                path: string | null;
                url: string;
            },
            thumbnail: {
                name: string;
                hash: string;
                ext: string;
                mime: string;
                width: number;
                height: number;
                size: number;
                path: string | null;
                url: string;
            }
        };
        hash: string;
        ext: string;
        mime: string;
        size: number;
        url: string;
        previewUrl: string | null;
        provider: string;
        provider_metadata: string | null;
        created_at: string;
        updated_at: string;
    }
};

export type {StrapiDefaultAttributes, StrapiDefaultImage};